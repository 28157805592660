import React, {useState, useEffect} from "react"
import Config from "../config"
import ReactPixel from "react-facebook-pixel";

export default function ThankYou() {

    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    });

    return (
        <>
             <div data-wf-quick-add-section="nav" className="section wf-section">
                <div className="columns top-nav w-row">
                <div className="w-col w-col-4">
                    <a href="https://invisiblesfriends.co/" target="_blank" className="w-inline-block"><img src="images/shop.png" loading="lazy" alt="" /></a>
                </div>
                <div className="column w-col w-col-4"><img src="images/handshake.svg" loading="lazy" width="50" alt="" /></div>
                <div className="column-2 social-icon w-col w-col-4">
                    <a href="https://twitter.com/InvsbleFriends" target="_blank" className="twitter w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="25" alt=""/></a>
                    <a href="https://www.instagram.com/invisiblefriends_io/" target="_blank" className="instagram w-inline-block"><img src="images/instagram.svg" loading="lazy" width="25" alt=""/></a>
                    <a href="https://discord.com/invite/rndm" target="_blank" className="discord w-inline-block"><img src="images/discord-logo.svg" loading="lazy" width="25" alt="" className="image"/></a>
                </div>
                </div>
            </div>
            <div data-wf-quick-add-section="body" className="section-2 wf-section">                
                <div className="div-block-2" style={{paddingTop: "150px"}}>
                    <h1 className="heading-6">Congratulations, you are now an Invisible Friends Holder!</h1>
                    <div className="w-embed">    
                        <p className="tracking-wider" id="status"> Thanks for minting!</p>                            
                    </div>          
                </div>
            </div>
            <div className="section-5 wf-section">                
            </div>
            <div className="section-3 wf-section">
            
            </div>
            <div className="section-4 border-top wf-section">
                <div className="div-block-3"><img src="images/bingbong.svg" loading="lazy" width="100" alt="" />
                <div className="div-block-4">
                    <a href="https://twitter.com/InvsbleFriends" target="_blank" className="twitter w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="30" alt="" /></a>
                    <a href="https://www.instagram.com/invisiblefriends_io/" target="_blank" className="instagram w-inline-block"><img src="images/instagram.svg" loading="lazy" width="30" alt="" /></a>
                    <a href="https://discord.com/invite/rndm" target="_blank" className="discord w-inline-block"><img src="images/discord-logo.svg" loading="lazy" width="30" alt="" className="image" /></a>
                </div>
                </div>
            </div>
        </>
    )
}