import React, {useState, useEffect} from "react"
import Config from "../config"
import ReactPixel from "react-facebook-pixel";

export default function PublicMintView({
    connect,
    isConnected,
    isDataLoaded,
    isSoldOut,
    mint,
    isMinting,
    error,
    txHash,
    price
}) {

    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    });

    useEffect(() => {
        if (isConnected && isDataLoaded()) {            
            mint(amount)
        }
      }, [isConnected, isDataLoaded()])

    useEffect(() => {
        function countdown(minutes) {
            var seconds = 60;
            var mins = minutes
            function tick() {
                //This script expects an element with an ID = "counter". You can change that to what ever you want. 
                var counter = document.querySelector(".time-view");
                var current_minutes = mins-1
                seconds--;
                counter.innerHTML = current_minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
                if( seconds > 0 ) {
                    setTimeout(tick, 1000);
                } else {
                    if(mins > 1){
                        countdown(mins-1);           
                    }else{
                        countdown(15);
                    }
                }
            }
            tick();
        }
        countdown(15);
    }, [])

    const [amount, setAmount] = useState(1);

    const incrementAmount = () => {
        setAmount(a => a + 1)
    }

    const decrementAmount = () => {
        if (amount > 1) {
            setAmount(a => a - 1)
        }
    }                                                                             
        
    const UserMint = () => {
            return (
            <>           
                {                   
                    <>    
                    
                        <div className="w-embed">
                            {
                                !isMinting &&
                                <>
                                    <div id="spinner" className="spinner" style={{color: "white", animation: "none"}}>
                                    <button onClick={decrementAmount} type="button" className="btn-minus" style={{
                                                        background: "white",
                                                        position: "relative",
                                                        color: "#445c7f",
                                                        fontWeight: "700",
                                                        textAlign: "center",
                                                        letterSpacing: "2px",
                                                        backgroundPosition: "0 -15",
                                                        padding: "7px 20px",
                                                        border: "0px solid black",
                                                        borderRadius: "88px",
                                                        fontSize: "20px",
                                                        marginRight: "10px"
                                    }}>-</button>
                                    <input value={amount} onChange={() => 1} type="text" data-ruler="quantity" id="totalNFT" style={{
                                        border: "0px solid black",
                                        textAlign: "center",
                                        background: "white",
                                        color: "#445c7f", 
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        borderRadius: "50px",
                                        width: "200px",
                                        lineHeight: "1.5",
                                        fontSize: "20px"
                                    }} readOnly/>
                                    <button onClick={incrementAmount} type="button" className="btn-plus" style={{
                                        background: "white",
                                        position: "relative",
                                        color: "#445c7f",
                                        fontWeight: "700",
                                        textAlign: "center",
                                        letterSpacing: "2px",
                                        backgroundPosition: "0 -15",
                                        padding: "7px 20px",
                                        border: "0px solid black",
                                        borderRadius: "88px",
                                        fontSize: "20px",
                                        marginLeft: "10px"
                                    }}>+</button>
                                    </div>
                                    <br/>
                                    <p className="tracking-wider" id="status">Please connect your Wallet before minting.</p>
                                    <br/>
                                    <div className="flex items-center justify-center text-base md:text-xl space-x-2">
                                    <span onClick={() => !isConnected ? connect() : mint(amount)} className="bg-white btn text-sea-600" id="checkoutBtn" style={{cursor: "pointer"}}>
                                        Mint
                                    </span>
                                    </div>
                                </>
                            }

                            {
                                isMinting && !error &&
                                <>
                                        
                                    <p className="tracking-wider" id="status">Your mint is loading...</p>                            
                                    {
                                        txHash &&
                                        <a href={Config.URLS.etherscan + txHash} target="_blank" className="bg-white btn text-sea-600" id="checkoutBtn" style={{cursor: "pointer"}}>
                                            VIEW TRANSACTION
                                        </a>
                                    }
                                </>
                            }
                            {
                                error && 
                                <>
                                    <div>
                                        <br />
                                        <p className="tracking-wider" id="status" style={{color: "red", marginTop: "20px"}}>{error}</p>                            
                                    </div> 
                                </>
                            } 
                        </div>       
                    </>
                }     
            </>
        )
    }    

    const SoldOutSection = () => {
        return (
            <>
                {
                     isDataLoaded() && isSoldOut() &&
                    <>
                        <div className="w-embed">
                            <p className="tracking-wider" id="status">Minting sold out!</p>                            
                        </div> 
                    </>
                }
            </>
        )
    }

    return (
        <>
             <div data-wf-quick-add-section="nav" className="section wf-section">
                <div className="columns top-nav w-row">
                <div className="w-col w-col-4">
                    <a href="https://invisiblesfriends.co/" target="_blank" className="w-inline-block"><img src="images/shop.png" loading="lazy" alt="" /></a>
                </div>
                <div className="column w-col w-col-4"><img src="images/handshake.svg" loading="lazy" width="50" alt="" /></div>
                <div className="column-2 social-icon w-col w-col-4">
                    <a href="https://twitter.com/InvsbleFriends" target="_blank" className="twitter w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="25" alt=""/></a>
                    <a href="https://www.instagram.com/invisiblefriends_io/" target="_blank" className="instagram w-inline-block"><img src="images/instagram.svg" loading="lazy" width="25" alt=""/></a>
                    <a href="https://discord.com/invite/rndm" target="_blank" className="discord w-inline-block"><img src="images/discord-logo.svg" loading="lazy" width="25" alt="" className="image"/></a>
                </div>
                </div>
            </div>
            <div data-wf-quick-add-section="body" className="section-2 wf-section">
                <p className="paragraph"><strong className="bold-text">INVISIBLE<br/>FRIENDS<br/></strong></p>
                <div className="div-block-2"><img src="images/invisible-montage.gif" loading="lazy" alt="" className="image-2" />
                <h1 className="heading-6">WHITELIST : <span className="text-span">SOLD OUT</span></h1>
                <h1 className="heading">4974<strong className="bold-text-2">/5000</strong></h1>
                <h1 className="heading-2"><strong>MINTED</strong></h1>
                <h1 className="heading-7">Only <span className="text-span-3">26</span> NFTs left</h1>
                <div className="w-embed">
                    <h1 className="heading-5" style={{paddingLeft: "96px"}}>Total: <span id="totalETH">{(price * amount).toFixed(price.length === 3 ? 1 : 2)}</span> ETH <span id="strike-through" className="eth-strike strikeout">{((price * amount) / .5 ).toFixed(2)} ETH</span></h1>
                </div>
                    <UserMint />
                    <SoldOutSection />               
                </div>
                <div className="html-embed w-embed">
                <h1 className="time-head">Mint a minimum of 4 NFTs to get 50% OFF</h1>
                <h1 className="time-head">This Exclusive Offer ends in</h1>
                <div className="time-view">1 </div>
                </div>
            </div>
            <div className="section-5 wf-section">
                <div className="columns-2 play-video w-row">
                <div className="column-5 w-col w-col-2">
                    <div data-poster-url="videos/invsble_1-poster-00001.jpg" data-video-urls="videos/invsble_1-transcode.mp4,videos/invsble_1-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video w-background-video w-background-video-atom">
                        <video autoPlay loop style={{backgroundImage: "url(&quot;videos/invsble_1-poster-00001.jpg&quot;)"}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="videos/invsble_1-transcode.mp4" data-wf-ignore="true"/>
                        <source src="videos/invsble_1-transcode.webm" data-wf-ignore="true"/>
                    </video></div>
                </div>
                <div className="column-6 w-col w-col-2">
                    <div data-poster-url="videos/invsble_2-poster-00001.jpg" data-video-urls="videos/invsble_2-transcode.mp4,videos/invsble_2-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom">
                    <video autoPlay loop style={{backgroundImage: "url(&quot;videos/invsble_2-poster-00001.jpg&quot;)"}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="videos/invsble_2-transcode.mp4" data-wf-ignore="true"/>
                        <source src="videos/invsble_2-transcode.webm" data-wf-ignore="true"/>
                    </video></div>
                </div>
                <div className="column-7 w-col w-col-2">
                    <div data-poster-url="videos/invsble_3-poster-00001.jpg" data-video-urls="videos/invsble_3-transcode.mp4,videos/invsble_3-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-3 w-background-video w-background-video-atom">
                        <video autoPlay loop style={{backgroundImage: "url(&quot;videos/invsble_3-poster-00001.jpg&quot;)"}} muted  playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="videos/invsble_3-transcode.mp4" data-wf-ignore="true" />
                        <source src="videos/invsble_3-transcode.webm" data-wf-ignore="true" />
                    </video></div>
                </div>
                <div className="column-8 w-col w-col-3">
                    <div data-poster-url="videos/invsble_4-poster-00001.jpg" data-video-urls="videos/invsble_4-transcode.mp4,videos/invsble_4-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-4 w-background-video w-background-video-atom">
                        <video autoPlay loop style={{backgroundImage: "url(&quot;videos/invsble_4-poster-00001.jpg&quot;)"}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="videos/invsble_4-transcode.mp4" data-wf-ignore="true"/>
                        <source src="videos/invsble_4-transcode.webm" data-wf-ignore="true"/>
                    </video></div>
                </div>
                <div className="w-col w-col-3">
                    <div data-poster-url="videos/invsble_5-poster-00001.jpg" data-video-urls="videos/invsble_5-transcode.mp4,videos/invsble_5-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-5 w-background-video w-background-video-atom">
                        <video autoPlay loop style={{backgroundImage: "url(&quot;videos/invsble_5-poster-00001.jpg&quot;)"}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="videos/invsble_5-transcode.mp4" data-wf-ignore="true"/>
                        <source src="videos/invsble_5-transcode.webm" data-wf-ignore="true"/>
                    </video></div>
                </div>
                </div>
            </div>
            <div className="section-3 wf-section">
                <div className="columns-3 w-row">
                <div className="column-3 w-col w-col-6"><img src="images/Logan-PaulYellow.jpeg" loading="lazy" width="150" sizes="(max-width: 479px) 69vw, 150px" srcSet="images/Logan-PaulYellow-p-500.jpeg 500w, images/Logan-PaulYellow-p-800.jpeg 800w, images/Logan-PaulYellow-p-1080.jpeg 1080w, images/Logan-PaulYellow.jpeg 1350w" alt="" className="image-4" />
                    <h1 className="heading-3">Logan Paul</h1>
                    <h3 className="heading-4">Minted 5 Invisible Friends last Week</h3>
                    <a href="https://twitter.com/loganpaul" target="_blank" className="w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="40" alt=""/></a>
                </div>
                <div className="column-4 w-col w-col-6"><img src="images/Snoop-DoggBlue.png" loading="lazy" width="150" sizes="(max-width: 479px) 69vw, 150px" srcSet="images/Snoop-DoggBlue-p-500.png 500w, images/Snoop-DoggBlue.png 502w" alt="" className="image-3" />
                    <h1 className="heading-3">Snoop Dogg</h1>
                    <h3 className="heading-4">Has just joined the Club</h3>
                    <a href="https://twitter.com/SnoopDogg/status/1499339900226654209" target="_blank" className="w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="40" alt="" /></a>
                </div>
                </div>
                <div className="columns-4 w-row">
                <div className="column-9 w-col w-col-6"><img src="images/handshake.svg" loading="lazy" width="100" alt="" />
                    <h1 className="heading-3">Markus<br/>Magnusson</h1>
                    <h3 className="heading-4">INVISIBLE FRIENDS are thought up, drawn and animated by this one Swedish dude.</h3>
                    <a href="https://twitter.com/MotionMarkus" target="_blank" className="w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="40" alt="" /></a>
                </div>
                <div className="column-10 w-col w-col-6"><img src="images/rcc.png" loading="lazy" width="100" sizes="(max-width: 479px) 69vw, 100px" srcSet="images/rcc-p-500.png 500w, images/rcc.png 1200w" alt="" className="image-5" />
                    <h1 className="heading-3">Random Character<br/>Collective</h1>
                    <h3 className="heading-4">INVISIBLE FRIENDS are a proud member of Random Character Collective. The internet&#x27;s friendliest animator collective.</h3>
                    <a href="https://twitter.com/rndmcharacters" target="_blank" className="w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="40" alt="" /></a>
                </div>
                </div>
            </div>
            <div className="section-4 border-top wf-section">
                <div className="div-block-3"><img src="images/bingbong.svg" loading="lazy" width="100" alt="" />
                <div className="div-block-4">
                    <a href="https://twitter.com/InvsbleFriends" target="_blank" className="twitter w-inline-block"><img src="images/twitter-logo.svg" loading="lazy" width="30" alt="" /></a>
                    <a href="https://www.instagram.com/invisiblefriends_io/" target="_blank" className="instagram w-inline-block"><img src="images/instagram.svg" loading="lazy" width="30" alt="" /></a>
                    <a href="https://discord.com/invite/rndm" target="_blank" className="discord w-inline-block"><img src="images/discord-logo.svg" loading="lazy" width="30" alt="" className="image" /></a>
                </div>
                </div>
            </div>
        </>
    )
}