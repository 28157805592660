import React, { useState } from "react";
import useWeb3 from "../hooks/useWeb3";
import PublicMintView from "../components/publicMintView";
import useMintThree from "../hooks/useMintThree";
import "./index.css";

export default function PublicThree () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
      ] = useWeb3();

    /** TIMING */

    const [
        isDataLoaded,
        isSoldOut,
        mint,
        isMinting,
        error,
        txHash
    ] = useMintThree(contractSales, signer, provider, address)

    return (
       <PublicMintView
            connect={connect}
            isConnected={isConnected}        
            isDataLoaded={isDataLoaded}
            isSoldOut={isSoldOut}
            mint={mint}
            isMinting={isMinting}
            error={error}
            txHash={txHash}
            price={"0.3"}
       />
    )
}